export default {
	global: {
		skipToContent: "Saltar al contenido",
		readMore: "Leer más",
		seeMore: "Ver más",
		seeLess: "Ver menos",
		availableSoon: "Disponible muy pronto",
		followUs: "Síguenos",
		filter: "Filtrar",
		continue: "Continuar",
		hour: "Hora",
		date: "Fecha",
		send: "Enviar",
		emailUs: "Envíanos un email",
		of: "de",
	},

	categories: {
		client: "Cliente",
		industry: "Industria",
		services: "Servicios",
		team: "Equipo",
		date: "Fecha",
		skills: "Competencias",
	},

	elements: {
		selectDropdown: {
			clearSelection: "Limpiar selección",
		},
	},

	components: {
		playButton: {
			watchVideo: "Ver",
		},
	},

	modules: {
		contact: {
			scheduleCoffee: "Agendar un café (videollamada)",
			writeEmail: "Escribir un email",
			makeCall: "Hacer una llamada",
			weProposeDates: "Te proponemos fechas",
			youProposeDates: "Nos propones tu disponibilidad",
			previousStep: "Ir al paso anterior",
			privacyPolicy:
				"He podido leer y entiendo la información sobre el uso de mis datos personales explicada en la {{privacyPolicyLink}} y acepto recibir comunicaciones comerciales a través del email.",
			form: {
				name: {
					label: "Nombre",
					placeholder: "Nombre y apellidos",
					error: "Debes introducir un nombre válido",
				},
				email: {
					label: "Correo electrónico profesional",
					placeholder: "Correo electrónico",
					error: "Debes escribir un email válido",
				},
				message: {
					label: "Qué deberíamos saber de cara a nuestra reunión",
					placeholder: "Tu mensaje",
				},
				company: {
					label: "Empresa",
					placeholder: "Nombre Empresa",
					error: "Por favor, indícanos cuál es el nombre de tu empresa",
				},
				phone: {
					label: "Teléfono",
					placeholder: "+34 000 000 000",
					error: "Debes introducir un número de teléfono válido",
				},
				timeSlot: {
					label: "¿Cuándo prefieres que te llamemos?",
					placeholder: "Elige la franja horaria",
				},
			},
			error:
				"Ups! Parece que algo ha ido mal. Por favor, rellena el formulario para que podamos ponernos en contacto contigo.",
			errorButton: "Ir al formulario",
		},
	},
};
