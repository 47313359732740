// Gatsby SSR API

const griddoWebfonts = (
	<link
		rel="preload"
		href="/webfonts.css"
		as="style"
		// This id is required in order to usen it later in the `builder.browser.js`
		id="griddo-webfonts"
		crossOrigin
		// This onload only works on Griddo Editor (AX)
		// The code to trigger the onload for the build phase (CX) is th builder.browser.js
		onLoad="this.onload=null;this.rel='stylesheet'"
	/>
);

const cookiebotScript = (
	<script
		id="Cookiebot"
		src="https://consent.cookiebot.com/uc.js"
		data-cbid="97d47899-48fd-44ee-8587-ea0430f54311"
		data-blockingmode="auto"
		type="text/javascript"
	/>
);

// onRenderBody hook
function onRenderBody({ setHeadComponents, setBodyAttributes, pathname }) {
	// common headComponents
	const commonHeadComponents = [griddoWebfonts];

	// Set different scripts for Griddo Builder and Griddo Editor.
	const headComponents =
		pathname === "ax-editor"
			? [...commonHeadComponents]
			: [...commonHeadComponents, cookiebotScript];

	setHeadComponents(headComponents);
	setBodyAttributes({ id: "griddo-site" });
}

export default {
	onRenderBody,
};
