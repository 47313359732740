const allModules = [
	"BasicContent",
	"BasicText",
	"CardCollection",
	"CardCollectionSlider",
	"CaseDistributor",
	"Cases",
	"CustomImage",
	"CustomVideo",
	"CypherCollection",
	"DataCollection",
	"FeaturedAudiovisual",
	"FullVisual",
	"GraphicModules",
	"HorizontalScrollCardCollection",
	"MasonryAudiovisual",
	"PageDistributor",
	"ProjectDistributor",
	"Quote",
	"Slider",
	"Spacer",
	"Showcase",
	"Timeline",
	"TeamDistributor",
];

export { allModules };
